import { createWebHistory, createRouter } from "vue-router";



const routes = [
    {
      path: "/",
      name: "index",
      component: () => import('@/pages/index/index.vue'),
    },
    {
      path: "/index-fashion-two",
      name: "fashiontwo",
      component: () => import('@/pages/index/index-fashion-two'),
    },
    {
      path: "/user-agreement",
      name: "user-agreement",
      component: () => import('@/pages/user-agreement.vue'),
    },
    {
      path: "/privacy-agreement",
      name: "privacy-agreement",
      component: () => import('@/pages/privacy-agreement.vue'),
    },
    {
      path: "/legal-provisions",
      name: "legal-provisions",
      component: () => import('@/pages/legal-provisions.vue'),
    },
    {
      path: "/distribution-policy",
      name: "distribution-policy",
      component: () => import('@/pages/distribution-policy.vue'),
    },
    {
      path: "/cancellation-agreement",
      name: "cancellation-agreement",
      component: () => import('@/pages/cancellation-agreement.vue'),
    },
    {
      path: "/refund-policy",
      name: "refund-policy",
      component: () => import('@/pages/refund-policy.vue'),
    },
    {
      path: "/index-fashion-three",
      name: "fashionthree",
      component: () => import('@/pages/index/index-fashion-three'),
    },
    {
      path: "/index-fashion-four",
      name: "fashionfour",
      component: () => import('@/pages/index/index-fashion-four'),
    },
    {
      path: "/shop-grid",
      name: "Shop Grid",
      component: () => import('@/pages/shop/shop-grid/shop-grid.vue'),
    },
    {
      path: "/shop-grid-left-sidebar",
      name: "SHOP GRID",
      component: () => import('@/pages/shop/shop-grid/shop-grid-left-sidebar.vue'),
    },
    {
      path: "/shop-grid-right-sidebar",
      name: "Shop grid",
      component: () => import('@/pages/shop/shop-grid/shop-grid-right-sidebar.vue'),
    },
    {
      path: "/shop-list",
      name: "Shop list",
      component: () => import('@/pages/shop/shop-list/shop-list.vue'),
    },
    {
      path: "/shop-list-left-sidebar",
      name: "Shop List",
      component: () => import('@/pages/shop/shop-list/shop-list-left-sidebar.vue'),
    },
    {
      path: "/shop-list-right-sidebar",
      name: "Shop Right",
      component: () => import('@/pages/shop/shop-list/shop-list-right-sidebar.vue'),
    },
    {
      path: "/product-detail-one",
      name: "Procut",
      component: () => import('@/pages/shop/shop-detail/product-detail-one.vue'),
    },
    {
      path: "/product-detail-two",
      name: "Procuts",
      component: () => import('@/pages/shop/shop-detail/product-detail-two.vue'),
    },
    {
      path: "/product-detail-three",
      name: "ProcutS",
      component: () => import('@/pages/shop/shop-detail/product-detail-three.vue'),
    },
    {
      path: "/shop-cart",
      name: "shop cart",
      component: () => import('@/pages/shop/shop-cart.vue'),
    },
    {
      path: "/shop-checkout",
      name: "shop checkout",
      component: () => import('@/pages/shop/shop-checkout.vue'),
    },
    {
      path: "/our-store",
      name: "our store",
      component: () => import('@/pages/shop/our-store.vue'),
    },
    {
      path: "/brands",
      name: "brands",
      component: () => import('@/pages/shop/brands.vue'),
    },
    {
      path: "/compare-product",
      name: "compare-product",
      component: () => import('@/pages/shop/compare-product.vue'),
    },
    {
      path: "/recently-viewed-product",
      name: "recently-viewed-product",
      component: () => import('@/pages/shop/recently-viewed-product.vue'),
    },
    {
      path: "/sale",
      name: "sale",
      component: () => import('@/pages/sale.vue'),
    },
    {
      path: "/user-account",
      name: "useraccount",
      component: () => import('@/pages/account/user-account.vue'),
    },
   
    {
      path: "/aboutus",
      name: "aboutus",
      component: () => import('@/pages/aboutus.vue'),
    },
    {
      path: "/contact",
      name: "contact",
      component: () => import('@/pages/contact.vue'),
    },
    {
      path: "/career",
      name: "career",
      component: () => import('@/pages/career.vue'),
    },
    {
      path: "/blogs",
      name: "blog",
      component: () => import('@/pages/blog/blogs.vue'),
    },
    {
      path: "/blog-detail",
      name: "blogs",
      component: () => import('@/pages/blog/blog-detail.vue'),
    },
    {
      path: "/helpcenter",
      name: "helpcenter",
      component: () => import('@/pages/helpcenter/helpcenter.vue'),
    },
    {
      path: "/helpcenter-faqs",
      name: "helpcenter-faqs",
      component: () => import('@/pages/helpcenter/helpcenter-faqs.vue'),
    },
    {
      path: "/helpcenter-guides",
      name: "helpcenter-guides",
      component: () => import('@/pages/helpcenter/helpcenter-guides.vue'),
    },
    {
      path: "/helpcenter-support",
      name: "helpcenter-support",
      component: () => import('@/pages/helpcenter/helpcenter-support.vue'),
    },
    {
      path: "/login",
      name: "login",
      component: () => import('@/pages/authpages/login.vue'),
    },
    {
      path: "/signup",
      name: "signup",
      component: () => import('@/pages/authpages/signup.vue'),
    },
    {
      path: "/signup-success",
      name: "signup-success",
      component: () => import('@/pages/authpages/signup-success.vue'),
    },
    {
      path: "/forgot-password",
      name: "forgot-password",
      component: () => import('@/pages/authpages/forgot-password.vue'),
    },
    {
      path: "/lock-screen",
      name: "lock-screen",
      component: () => import('@/pages/authpages/lock-screen.vue'),
    },
    {
      path: "/terms",
      name: "terms",
      component: () => import('@/pages/utility/terms.vue'),
    },
    {
      path: "/privacy",
      name: "privacy",
      component: () => import('@/pages/utility/privacy.vue'),
    },
    {
      path: "/comingsoon",
      name: "comingsoon",
      component: () => import('@/pages/specialpages/comingsoon.vue'),
    },
    {
      path: "/maintenance",
      name: "maintenance",
      component: () => import('@/pages/specialpages/maintenance.vue'),
    },
    {
      path: "/email-order-success",
      name: "email",
      component: () => import('@/pages/email/email-order-success.vue'),
    },
    {
      path: "/email-offers",
      name: "Email Offer",
      component: () => import('@/pages/email/email-offers.vue'),
    },
    {
      path: "/email-item-review",
      name: "email review",
      component: () => import('@/pages/email/email-item-review.vue'),
    },
    {
      path: "/email-confirmation",
      name: "email confirmation",
      component: () => import('@/pages/email/email-confirmation.vue'),
    },
    {
      path: "/email-cart",
      name: "email cart",
      component: () => import('@/pages/email/email-cart.vue'),
    },
    {
      path: "/email-gift-voucher",
      name: "email-gift-voucher",
      component: () => import('@/pages/email/email-gift-voucher.vue'),
    },
    {
      path: "/email-reset-password",
      name: "email-reset-password",
      component: () => import('@/pages/email/email-reset-password.vue'),
    },
    {
      path: "/user-billing",
      name: "user-billing",
      component: () => import('@/pages/account/user-billing.vue'),
    },
    {
      path: "/user-payment",
      name: "user-payment",
      component: () => import('@/pages/account/user-payment.vue'),
    },
    {
      path: "/user-invoice",
      name: "user-invoice",
      component: () => import('@/pages/account/user-invoice.vue'),
    },
    {
      path: "/user-social",
      name: "user-social",
      component: () => import('@/pages/account/user-social.vue'),
    },
    {
      path: "/user-notification",
      name: "user-notification",
      component: () => import('@/pages/account/user-notification.vue'),
    },
    {
      path: "/user-setting",
      name: "user-setting",
      component: () => import('@/pages/account/user-setting.vue'),
    },
   
    {
      path: "/:catchAll(.*)",
      name: "Error",
      component: () => import('@/pages/specialpages/error.vue'),
    },
   
   
  ];
  

  const router = createRouter({
    history: createWebHistory(),
    routes,
  });

  export default router;